const modal = {

	$window: $(window),

	elem: {
		$body: $('body'),
		$ajax: $('.js-ajax-modal'),
		$inline: $('.js-inline-modal')
	},

	init() {
		this.addDataParamForAjax();
		this.bindUIActions();
	},

	bindUIActions() {

		this.elem.$ajax.magnificPopup({
			type: 'ajax',
			closeOnContentClick: false,
			closeOnBgClick: true,
			closeMarkup: '<button title="%title" type="button" class="mfp-close "><span class="mfp-close__text">Close</span><span class="show-for-sr"> Modal Window</span> <svg class="brei-icon brei-icon-close" focusable="false"><use xlink:href="#brei-icon-close"></use></svg></button>'
		});

		this.elem.$inline.magnificPopup({
			type: 'inline',
			closeOnContentClick: false,
			closeOnBgClick: true,
			closeMarkup: '<button title="%title" type="button" class="mfp-close "><span class="mfp-close__text">Close</span><span class="show-for-sr"> Modal Window</span> <svg class="brei-icon brei-icon-close" focusable="false"><use xlink:href="#brei-icon-close"></use></svg></button>'
		});

	},

	addDataParamForAjax() {

		let url = this.elem.$ajax.attr('href');

		url += '?data=1';

		this.elem.$ajax.attr('href', url);

	}

};

export {modal};
