import { mql } from '../lib/utils';
import {ResponsiveAccordionTabs} from 'foundation-sites';

const tabs = {

	$window: $(window),

	elem: {
		$tabs: $('.feature-tabs__widget, .tabbed__widget'),
		$nav: $('.feature-tabs__navigation, .tabbed__navigation')
	},

	init() {
		if ( this.elem.$tabs.length > 0) {
			this.tabs = new ResponsiveAccordionTabs(this.elem.$tabs) - 1;

			this.elem.$nav.on('click', function(e) {
				e.preventDefault();
				if ($(this).attr('rel') === 'prev') {
					$(this).parent().find('.is-active').prev('li').children('a').click();
				}
				if ($(this).attr('rel') === 'next') {
					$(this).parent().find('.is-active').next('li').children('a').click();
				}
			});

			this.elem.$tabs.on('change.zf.tabs', function() {
				let $closest = $(this).closest('.feature-tabs__container, .tabbed__container');
				if($closest.hasClass('overscroll')) {
					let $active = $(this).find('.is-active');
					let length = $closest.find('.feature-tabs__tab, .tabbed__tab').length - 1;

					if ($active.index() === 0) {
						$closest.find('[rel=prev]').addClass('btn--disabled');
					} else {
						$closest.find('[rel=prev]').removeClass('btn--disabled');
					}
					if ($active.index() === length) {
						$closest.find('[rel=next]').addClass('btn--disabled');
					} else {
						$closest.find('[rel=next]').removeClass('btn--disabled');					
					}
					$(this).scrollLeft(0);
					$(this).scrollLeft($active.position().left);
				}
			});

			$(window).on('resize', this.overscroll.bind(this));
			this.overscroll();
		}
	},

	overscroll() {
		if(mql.large.matches) {
			this.elem.$tabs.each(function() {
				if (this.scrollWidth > this.clientWidth) {
					$(this).parent().addClass('overscroll');

					let length = $(this).parent().find('.feature-tabs__widget, .tabbed__tab').length - 1;
					let active = $(this).parent().find('.is-active').index();

					if (active === 0) {
						$(this).parent().find('[rel=prev]').addClass('btn--disabled');
					}
					if (active === length) {
						$(this).parent().find('[rel=next]').addClass('btn--disabled');
					}			

				} else {
					$(this).parent().removeClass('overscroll');
					$(this).parent().find('[rel]').removeClass('btn--disabled');
				}
			});
		} else {
			this.elem.$tabs.each(function() {
				$(this).parent().removeClass('overscroll');
				$(this).parent().find('[rel]').removeClass('btn--disabled');
			});
		}
	}

};

export { tabs };
