import { mql } from '../lib/utils';

const navSide = {

	elem: {
		$children: $('.side-nav__children'),
		$menu: $('.side-nav__inner'),
		$toggle: $('.side-nav__toggle'),
		$trigger: $('.side-nav__trigger')
	},

	init() {
		this.elem.$toggle.on('click', function() {
			navSide.elem.$toggle.attr({
				'aria-expanded': function (i, txt) {
					return (txt === 'false') ? 'true' : 'false';
				}
			});
			navSide.elem.$menu.attr({
				'aria-expanded': function (i, txt) {
					return (txt === 'false') ? 'true' : 'false';
				}
			}).slideToggle();
		});

		this.elem.$trigger.on('click', function() {
			let state = !($(this).attr('aria-expanded') === 'true');
			let controls = $(this).parent().parent().children('.side-nav__children');
			$(this).attr({ 'aria-expanded' : state });
			controls.attr({ 'aria-expanded' : state }).slideToggle();
		});

		$(window).on('resize', this.reset.bind(this));
		this.reset();
	},

	reset() {
		if(mql.large.matches) {
			this.elem.$menu.removeAttr('style').attr({ 'aria-expanded': 'true' });
			this.elem.$toggle.attr({ 'aria-expanded': 'false' });
		}
	}

};

export { navSide };
