const stateAuths = {

	elem: {
		$dropdown: $('#stateauthorizations'),
		$states: $('.state-auths__state')
	},

	init() {

		this.elem.$dropdown.on('change', function() {
			let val = stateAuths.elem.$dropdown.val();
			stateAuths.elem.$states.slideUp();
			if (val !== '') {
				$('.state-auths__state--' + val).slideDown();
			}
		});

	}

};

export { stateAuths };

// to use:
//
// import stateAuths from './modules/stateAuths';
//
// stateAuths.init();
