import { ready } from './lib/utils';

import { forms } from './modules/forms';

import { accordions } from './modules/accordions';
import { modal } from './modules/modal';
import { navSide } from './modules/navSide';
import { stateAuths } from './modules/stateAuths';
import { table } from './modules/table';
import { tabs } from './modules/tabs';

const level = {
	init() {
		forms.init();

		accordions.init();
		modal.init();
		navSide.init();
		stateAuths.init();
		table.init();
		tabs.init();
	}
};

ready(function () {
	level.init();
});
