const table = {

	$window: $(window),

	elem: {
		$table: $('.table')
	},

	init() {
		if ( this.elem.$table.length > 0) {
			$(window).on('resize', this.sticky.bind(this));
			this.sticky();
		}
	},

	sticky() {
		this.elem.$table.each(function() {
			if (this.scrollWidth > this.parentNode.clientWidth) {
				this.classList.add('sticky');
			} else {
				this.classList.remove('sticky');
			}
		});
	}

};

export { table };
