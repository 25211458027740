/**
 * Accordions component.
 * @module components/accordions
 */
import { Accordion } from 'foundation-sites';

const accordions = {

	elem: {
		$body: $('body'),
		$accordion: $('.accordions__accordion, .filter-checkboxes__accordion'),
		$collapse: $('.accordions__all--collapse'),
		$expand: $('.accordions__all--expand')
	},

	accordion: null,

	init() {

		// const _this = this;

		if (this.elem.$accordion.length > 0) {

			this.accordion = new Accordion(this.elem.$accordion);

			this.elem.$accordion
				.find('[data-accordion-item]:not(.is-active) .user-markup').css('opacity', 0)
				.end()
				.on('down.zf.accordion', function (e, $content) {
					$content.find('.user-markup').animate({
						'opacity': 1
					}, 125);
					console.log($(this).find('[data-accordion-item]:not(.is-active)').length);
					if( $(this).find('[data-accordion-item]:not(.is-active)').length === 0) {
						$(this).parent().find('.accordions__all--expand').hide();
					} else {
						$(this).parent().find('.accordions__all--collapse').show();
					}
				})
				.on('up.zf.accordion', function (e, $content) {
					$content.find('.user-markup').animate({
						'opacity': 0
					}, 125);
					if( $(this).find('[data-accordion-item].is-active').length === 0) {
						$(this).parent().find('.accordions__all--collapse').hide();
					} else {
						$(this).parent().find('.accordions__all--expand').show();
					}
				});

			if (!Foundation.MediaQuery.atLeast('large')) {

				this.elem.$accordion.foundation('up', $('.is-active'));

				this.elem.$accordion.find('.accordion-item.is-active .accordion-content').each(function () {

					let $p = $(this).parents('.accordion');

					$p.foundation('up', $(this));

				});

			};

			this.elem.$collapse.on('click', function() {
				let container = $(this).parent().parent();;
				container.find('.accordion').foundation('up', container.find('.accordion-content'));
				container.find('.accordions__all--collapse').hide();
				container.find('.accordions__all--expand').show();
			});

			this.elem.$expand.on('click', function() {
				let container = $(this).parent().parent();
				container.find('.accordion').foundation('down', container.find('.accordion-content'));
				container.find('.accordions__all--collapse').show();
				container.find('.accordions__all--expand').hide();
			});
		}

	}

};

export { accordions };

// to use:
//
// import accordions from './modules/accordions';
//
// accordions.init();
